import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';

export const setNumber = (value, onChange) => {
  const isDecimal = value.includes('.');
  if (!isDecimal) {
    if (value.length <= 3) {
      onChange(value);
    }
  } else {
    const sub = value.split('.');
    if (sub[1].length <= 2) {
      onChange(value);
    }
  }
};

export const setNumPositNegat = (value, onChange) => {
  value = value.replace(/[^0-9.-]/g, ''); // only allow numbers and "-" and "."
  const isDecimal = value.includes('.');
  const length = value.indexOf('-') !== -1 ? 4 : 3;

  if (!isDecimal) {
    if (value.length <= length) {
      onChange(value);
    }
  }

  const sub = value.split('.');
  if (sub.length === 2 && sub[0].length <= length && sub[1].length <= 2) {
    onChange(value);
  }
};

export const handleKeyPress = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    e.preventDefault();
  }
};

export const getMonthName = (num, language = 'es') => {
  const monthNames = {
    en: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    es: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
  };

  if (num < 0 || num > 11) {
    return 'Invalid month number';
  }

  return monthNames[language][num];
};

export const obtenerFechaActual = () => {
  let fecha = new Date();
  let anio = fecha.getFullYear();
  let mes = fecha.getMonth() + 1;
  let dia = fecha.getDate();
  let hora = fecha.getHours();
  let minutos = fecha.getMinutes();
  let segundos = fecha.getSeconds();

  if (mes < 10) {
    mes = '0' + mes;
  }
  if (dia < 10) {
    dia = '0' + dia;
  }
  if (hora < 10) {
    hora = '0' + hora;
  }
  if (minutos < 10) {
    minutos = '0' + minutos;
  }
  if (segundos < 10) {
    segundos = '0' + segundos;
  }

  let fechaActual =
    anio + '' + mes + '' + dia + ' ' + hora + '' + minutos + '' + segundos;

  return fechaActual;
};

export const isExpired = (isAuthenticated) => {
  try {
    const decoded = jwtDecode(isAuthenticated);
    const exp = decoded.exp * 1000;
    const now = new Date().getTime();
    const tokenIsExpired = exp < now;
    return tokenIsExpired;
  } catch (error) {
    return true;
  }
};

export const getTokenFromCookie = () => {
  const token = Cookies.get('token');
  return token;
};

export const dateToString = (date) => {

  if (date) {
    const tmp = new Date(`${date}T00:00:00`);
    const formattedDate = format(tmp, 'dd-MMM-yyyy', { locale: es });
    return formattedDate;
  } else {
    return date;
  }
};

export const dateDToString = (date) => {
  const formattedDate = format(date, 'dd-MMM-yyyy', { locale: es });
  return formattedDate;
};

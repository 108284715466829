import React from 'react';
import { Button } from 'primereact/button';
import '../css/SectionFive.css';
const SectionFive = () => {
    return (
        <>

        <div className='SectionFive'>
            <div className="grid mt-7 md:m-8 lg:m-8">
                <div className="col-12 md:col-6 lg:col-6">
                    <img src="https://img.freepik.com/foto-gratis/pareja-preparando-cocinando-pizza-vegetariana-cocina-juntos_52683-110967.jpg" alt="Cocineros" className="w-12 shadow-2 surface-border border-round" />
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <h1 className='title05'>NUESTRA COMUNIDAD</h1>
                    <h1 className='text05'>La Fuderia es el combo completo. Queremos consentirte más de dos veces por quincena.
</h1>
                    <h1 className='text05'>Y si quieres quedar bien con el match o tus amistades, te compartimos recetas usando lo mejor de nuestra cocina para que fácil y rápido comas como tú te mereces.</h1>
                    <div className="grid col-12 justify-content-center">
                        <Button className="ver-recetas-btn" label="VER RECETAS" />
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default SectionFive;
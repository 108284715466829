import { useContext, useEffect } from 'react';
import { setLayout, setProducts } from '../../../../redux/actions/shop/productViewerActions';

import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { Button } from 'primereact/button';
import { DataViewLayoutOptions } from 'primereact/dataview';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { classNames } from 'primereact/utils';

import global from '../../../../context/global.context';
import { addElementToCart } from '../../../../redux/actions/shop/shopActions';
import { useFetch } from '../../../../hooks/common/useFetchV2';
import '../../../home/LandingPage/css/products.css';

const useProductsViewHook = () => {
  const responsiveOptions = [
    {
      breakpoint: '1400px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '1199px',
      numVisible: 3,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 2,
      numScroll: 1
    },
    {
      breakpoint: '575px',
      numVisible: 1,
      numScroll: 1
    }
  ];
  const { sessionToken } = useContext(global);
  const dispatch = useDispatch();
  const state = useSelector((state) => state.productViewerReducer);
  const { layout, products } = state;

  useFetch('/public/products', 'GET', {
    onComplete: (data) => {
      console.log(data)
      dispatch(setProducts(data))
    }
  })

  const header = () => {
    return (
      <div className="flex justify-content-end">
        <DataViewLayoutOptions
          layout={layout}
          onChange={(e) => dispatch(setLayout(e.value))}
        />
      </div>
    );
  };

  const getSeverity = (product) => {
    switch (product.inventoryStatus) {
      case 'INSTOCK':
        return 'success';

      case 'LOWSTOCK':
        return 'warning';

      case 'OUTOFSTOCK':
        return 'danger';

      default:
        return null;
    }
  };

  const listItem = (product, index) => {
    return (
      <div className="col-12" key={product.id}>
        <div
          className={classNames(
            'flex flex-column xl:flex-row xl:align-items-start p-4 gap-4',
            { 'border-top-1 surface-border': index !== 0 },
          )}
        >
          <img
            className="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
            src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
            alt={product.name}
          />
          <div className="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4">
            <div className="flex flex-column align-items-center sm:align-items-start gap-3">
              <div className="proTitle">{product.name}</div>
              <Rating value={product.rating} readOnly cancel={false}></Rating>
              <div className="flex align-items-center gap-3">
                <span className="flex align-items-center gap-2">
                  <i className="pi pi-tag"></i>
                  <span className="font-semibold">{product.category}</span>
                </span>
                <Tag
                  value={product.inventoryStatus}
                  severity={getSeverity(product)}
                ></Tag>
              </div>
            </div>
            <div className="flex sm:flex-column align-items-center sm:align-items-end gap-3 sm:gap-2">
              <span className="text-2xl font-semibold">${product.price}</span>
              <Button
                icon="pi pi-shopping-cart"
                className="p-button-rounded"
                disabled={product.inventoryStatus === 'OUTOFSTOCK'}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function trimEndSpaces(str) {
    return str.replace(/\s+$/, '');
  }


  const addToCart = (product) => {
    const simpleElement = {
      id: product.productId.trim(),
      code: trimEndSpaces(product.productSku),
    };
    console.log(simpleElement);
    if (sessionToken) {
      //Guarda el carrito en base de datos
    }
    dispatch(addElementToCart(simpleElement));
  };

  const gridItem = (product) => {
    return (
      <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={product.id}>
        <div className="p-4 border-1 surface-border surface-card border-round">
          <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <div className="flex align-items-center gap-2">
              <i className="pi pi-tag"></i>
              <span className="font-semibold">{product.category}</span>
            </div>
            <Tag
              value={product.inventoryStatus}
              severity={getSeverity(product)}
            ></Tag>
          </div>
          <div className="flex flex-column align-items-center gap-3 py-5">
            <img
              className="w-9 shadow-2 border-round"
              src={`https://primefaces.org/cdn/primereact/images/product/${product.image}`}
              alt={product.name}
            />
            <div className="text-2xl font-bold">{product.name}</div>
            <Rating value={product.rating} readOnly cancel={false}></Rating>
          </div>
          <div className="flex align-items-center justify-content-between">
            <span className="text-2xl font-semibold">${product.price}</span>
            <Button
              icon="pi pi-shopping-cart"
              className="p-button-rounded"
              disabled={product.inventoryStatus === 'OUTOFSTOCK'}
              onClick={() => addToCart(product)}
            />
          </div>
        </div>
      </div>
    );
  };

  const itemTemplate = (product, layout, index) => {
    if (!product) {
      return;
    }

    if (layout === 'list') return listItem(product, index);
    else if (layout === 'grid') return gridItem(product);
  };

  const listTemplate = (products, layout) => {
    return (
      <div className="grid grid-nogutter">
        {products.map((product, index) => itemTemplate(product, layout, index))}
      </div>
    );
  };


  const listTemplateCarousel = (product) => {
    return (
      <div className="list-template-carousel border-1 surface-border border-round m-2 text-center py-5 px-3">
        <div className="mb-3">
          <img src={`${product.miniatureImageUrl ? product.miniatureImageUrl : 'https://img.freepik.com/vector-gratis/plantilla-etiqueta-personaje-dibujos-animados-lindo-pez-aislado_1308-58808.jpg'}`} alt={product.name} className="w-7 shadow-2" />
        </div>
        <div>
          <h2 className="mb-0">{product.productName}</h2>
          <h5 className="mt-0">
            {
              product.features.map((feature) => {
                if (feature.featureDesc === 'PESO EN GRAMOS') {
                  return (
                    <p key={feature.featureId}>
                      {feature.featureValue}gr
                    </p>
                  );
                }
              })
            }
          </h5>
          <h3 className="mt-0 mb-0">${product.price ? product.price : 500}</h3>
          <div className="mt-5 flex flex-wrap gap-2 justify-content-center mt-0">
            <Button icon="pi pi-shopping-cart" rounded onClick={() => addToCart(product)} />
            <Button icon="pi pi-heart" rounded />
            <Button icon="pi pi-share-alt" rounded severity="success" />
          </div>
        </div>
      </div>
    );
  };

  const hederJsx = header();

  return {
    functions: { listTemplate, listTemplateCarousel },
    states: { hederJsx, layout, products, responsiveOptions },
  };
};

export default useProductsViewHook;

import AboutUs from "./componets/SectionTwo";
import SectionFive from "./componets/SectionFive";
import SectionOne from "./componets/SectionOne";
import SectionThree from "./componets/SectionThree";
import SectionSix from "./componets/SectionSix";
import './css/index.css';



const LandingPage = () => {
    return (
        <>
            <SectionOne />
            <AboutUs/>
            <SectionThree/>
            <SectionFive/>
            <SectionSix />
        </>
    );
}

export default LandingPage;
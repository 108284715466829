import React, { useEffect, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { isExpired } from './common.action';
import { jwtDecode } from 'jwt-decode';
import global from '../context/global.context';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = sessionStorage.getItem('token');
  const [showRefreshDialog, setShowRefreshDialog] = useState(false);
  const { setSessionToken } = useContext(global);
  useEffect(() => {
    const checkTokenValidity = () => {
      console.log('Me llame');
      if (isAuthenticated) {
        try {
          const tokenIsExpired = isExpired(isAuthenticated);
          if (tokenIsExpired) {
            redirectToLogin();
          } else {
            // Calcular el tiempo restante antes de la expiración del token
            const expirationTime = getExpirationTime(isAuthenticated);
            const currentTime = Math.floor(Date.now() / 1000); // Obtener la hora actual en segundos
            const remainingTime = expirationTime - currentTime;
            if (remainingTime <= 120) {
              // 120 segundos = 2 minutos
              console.log('Sesión a punto de expirar');
              setShowRefreshDialog(true);
            }
          }
        } catch (error) {
          redirectToLogin();
        }
      } else {
        redirectToLogin();
      }
    };

    const intervalId = setInterval(checkTokenValidity, 60000); // Ejecutar cada 5 Minutos

    return () => {
      clearInterval(intervalId); // Limpiar el intervalo cuando el componente se desmonte
    };
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleConfirmRefresh = () => {
    // Lógica para refrescar el token aquí
    setShowRefreshDialog(false);
  };

  const handleCancelRefresh = () => {
    redirectToLogin();
  };

  const redirectToLogin = () => {
    sessionStorage.clear();
    setSessionToken(null);
    return <Navigate to="/public/no-permission" />;
  };

  const getExpirationTime = (token) => {
    // Decodificar el token JWT para obtener la fecha de expiración
    const decodedToken = jwtDecode(token);
    return decodedToken.exp;
  };

  return (
    <>
      <ConfirmDialog
        visible={showRefreshDialog}
        message="Tu sesión está a punto de expirar. ¿Deseas refrescar el token?"
        header="Sesión a punto de expirar"
        rejectLabel="No"
        acceptLabel="Sí"
        icon="pi pi-exclamation-triangle"
        accept={handleConfirmRefresh}
        reject={handleCancelRefresh}
      />

      {isAuthenticated && !isExpired(isAuthenticated)
        ? children
        : redirectToLogin()}
    </>
  );
};

export default PrivateRoute;

/**import React, { useEffect, useState } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { isExpired } from './common.action';

const PrivateRoute = ({ children }) => {
  const isAuthenticated = sessionStorage.getItem('token');
  const [showRefreshDialog, setShowRefreshDialog] = useState(false);
  //const tokenIsExpired = isExpired(isAuthenticated);
  const tokenIsExpired = true;

  useEffect(() => {
    const checkTokenValidity = () => {
      if (isAuthenticated) {
        try {
          
          if (tokenIsExpired) {
            setShowRefreshDialog(true);
          }
        } catch (error) {
          redirectToLogin();
        }
      } else {
        redirectToLogin();
      }
    };

    checkTokenValidity();
  }, [isAuthenticated,tokenIsExpired]);

  const handleConfirmRefresh = () => {
    // Lógica para refrescar el token aquí
    setShowRefreshDialog(false);
  };

  const handleCancelRefresh = () => {
    redirectToLogin();
  };

  const redirectToLogin = () => {
    sessionStorage.clear();
    return <Navigate to="/auth/sign-in" />;
  };

  return (
    <>
      <ConfirmDialog
        visible={showRefreshDialog}
        message="Tu sesión está a punto de expirar. ¿Deseas refrescar el token?"
        header="Sesión a punto de expirar"
        rejectLabel="No"
        acceptLabel="Sí"
        icon="pi pi-exclamation-triangle"
        accept={handleConfirmRefresh}
        reject={handleCancelRefresh}
      />

      {isAuthenticated ? children : redirectToLogin()}
    </>
  );
};

export default PrivateRoute;*/

/**import { Navigate } from "react-router-dom";
import { isExpired } from "./common.action";


export const PrivateRoute = ({ children }) => {
    const isAuthenticated = sessionStorage.getItem('token');

    if (isAuthenticated) {
        try {
            const tokenIsExpired = isExpired(isAuthenticated);
            // JWT exp is in seconds
            if (tokenIsExpired) {
                console.log("Token expired.");
                alert('Tu sesión ha terminado');
                sessionStorage.clear();
                sessionStorage.setItem('isExpired', 'true');
                return <Navigate to="/auth/sign-out" />
            } else {
                if (isAuthenticated) {
                    return children
                }
            }
        } catch (error) {
            sessionStorage.clear();
            return <Navigate to="/" />
        }

    }

    return <Navigate to="/" />


}*/

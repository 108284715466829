import React, { useContext } from 'react';

import useRouter from '../../hooks/common/useRouter';
import { Link } from 'react-router-dom';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { CSSTransition } from 'react-transition-group';
import { MenuContext } from './context/menucontext';
import { LayoutContext } from './context/layoutcontext';
import './style.css';

const AppMenuitem = (props) => {
  const { activeMenu, setActiveMenu } = useContext(MenuContext);
  const { onMenuToggle } = useContext(LayoutContext);

  const router = useRouter();
  const item = props.item;
  const key = props.parentKey
    ? props.parentKey + '-' + props.index
    : String(props.index);
  const isActiveRoute = item.to && router.pathname === item.to;
  const active = activeMenu === key || activeMenu.startsWith(key + '-');

  const itemClick = (event) => {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    //execute command
    if (item.command) {
      item.command({ originalEvent: event, item: item });
    }

    // toggle active state
    if (item.items) {
      setActiveMenu(active ? props.parentKey : key);
    } else {
      setActiveMenu(key);
    }
    onMenuToggle();
  };

  const subMenu = item.items && item.visible !== false && (
    <CSSTransition
      timeout={{ enter: 1000, exit: 450 }}
      classNames="layout-submenu"
      in={props.root ? true : active}
      key={item.label}
    >
      <ul>
        {item.items.map((child, i) => {
          return (
            <AppMenuitem
              item={child}
              index={i}
              className={child.badgeClass}
              parentKey={key}
              key={child.label}
            />
          );
        })}
      </ul>
    </CSSTransition>
  );

  return (
    <section className="menu">
      <li
        className={classNames({
          'layout-root-menuitem': props.root,
          'active-menuitem': active,
        })}
      >
        {props.root && item.visible !== false && (
          <div className="layout-menuitem-root-text">{item.label}</div>
        )}

        {(!item.to || item.items) && item.visible !== false && (
          <>
            {/* eslint-disable-next-line*/}

            <a
              href={item.url}
              onClick={(e) => itemClick(e)}
              className={classNames(item.className, 'p-ripple')}
              target={item.target}
              tabIndex="0"
            >
              <i className={classNames('layout-menuitem-icon', item.icon)} />
              <span className="layout-menuitem-text">{item.label}</span>
              {item.items && (
                <i className="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
              )}
              <Ripple />
            </a>
          </>
        )}

        {item.to && !item.items && item.visible !== false && (
          <Link to={item.to}>
            {/* eslint-disable-next-line*/}
            <a
              href="/#"
              onClick={(e) => itemClick(e)}
              className={classNames(item.className, 'p-ripple', {
                'active-route': isActiveRoute,
              })}
              target={item.target}
              tabIndex="0"
            >
              {item.svgIcon ? (
                <img
                  src={item.svgIcon}
                  style={{ width: '3.3rem' }}
                  alt="assigned-img"
                  className={classNames('layout-menuitem-icon', item.icon)}
                />
              ) : (
                <i
                  className={classNames('layout-menuitem-icon', item.icon)}
                  style={{ fontSize: '2.5rem' }}
                />
              )}
              <span className="layout-menuitem-text">{item.label}</span>
              {item.items && (
                <i className="pi pi-fw pi-angle-down layout-submenu-toggler" />
              )}
              <Ripple />
            </a>
          </Link>
        )}

        {subMenu}
      </li>
    </section>
  );
};

export default AppMenuitem;

import { Button } from 'primereact/button';
import usePriceBoxContainerHook from '../hooks/usePriceBoxContainerHook';


const PriceBoxContainer = () => {

    const { totalProducts, shipping, total, totalAndAhipping, continueFlow } = usePriceBoxContainerHook();

    return (
        <div className="flex flex-column">
            <div className="flex justify-content-between flex-wrap pb-5">
                <div>Productos ({totalProducts})</div>
                <div>$ {total}</div>
            </div>
            <div className="flex justify-content-between flex-wrap pb-5">
                <div>Envíos</div>
                <div>$ {shipping}</div>
            </div>

            <div className="flex justify-content-between flex-wrap pb-5">
                <div>Total</div>
                <div>$ {totalAndAhipping}</div>
            </div>
            <div>
                <Button label='Continuar compra' className='w-full' onClick={continueFlow}></Button>
            </div>
        </div>
    )
}

export default PriceBoxContainer;

import React, { useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { Button } from 'primereact/button';
import { InputMask } from "primereact/inputmask";
import useFormPayHook from "../hooks/useFormPayHook";
import { Controller, useForm } from 'react-hook-form';
import { Message } from 'primereact/message';
import { ToggleButton } from 'primereact/togglebutton';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { InputOtp } from 'primereact/inputotp';

const FormPayView = () => {
  const toast = useRef(null);
  const { functions } = useFormPayHook();
  const { onSubmit, getCodigoPostal, getBuscaCorreo, postValidateEmail } = functions;
  const [inputCorreo, setInputCorreo] = useState('');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [sameUser, setSameUser] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(false);
  const [token, setTokens] = useState('');
  const [mensajePago1, setMensajePago1] = useState('Para empezar a pagar');
  const [mensajePago2, setMensajePago2] = useState('Ingrese su correo electrónico...!');



  const {
    handleSubmit,
    control,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: '',
      apa: '',
      am: '',
      email: '',
      phone: '',
      calle: '',
      numInt: '',
      numExt: '',
      colonia: '',
      estado: '',
      delegacion: '',
      cp: '',
      cardName: '',
      cardNumber: '',
      vigencia: '',
      cvv2: ''
    }
  });

  const getFormErrorMessage = (name) => {
    const errorSmall = (
      <div>
        {errors[name] && (
          <Message
            text={errors[name].message}
            severity="error"
            style={{ width: '100%', marginTop: '.3rem', }}
          ></Message>
        )}
      </div>
    );
    return errorSmall;
  };

  const handleNextClick = async () => {
    const isValid = await trigger();
    if (isValid) {
      setActiveIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveIndex(prevIndex => prevIndex - 1);
  };

  const handleDisabled = async (e) => {
    e.preventDefault();
    await getBuscaCorreo(inputCorreo).then((data) => {
      console.log(data);

      setMensajePago1('Correo por validar');
      setMensajePago2('Revise su bandeja de entrada y valide su correo...!');

      if (!data.existCustomer) {
        showMessage('INFORMATIVO: Correo Electronico', 'Correo no regsitrado', 'info');
        setIsValidateEmail(true);
        return;
      }


      if (!data.customer.validatedCustomer) {
        showMessage('INFORMATIVO: Correo Electronico', 'Validar correo registrado', 'warn');
        setIsValidateEmail(true);
      }

      if (data.existCustomer && data.customer.validatedCustomer) {
        
        setValue('name', data.customer.firstName || null);
        setValue('apa', data.customer.lastName || null);
        setValue('phone', data.customer.phone || null);
        setValue('email', inputCorreo);
        if (data.addresses != null) {
          setValue('calle', data.addresses[0].street);
          setValue('numExt', data.addresses[0].externalNumber);
          setValue('numInt', data.addresses[0].internalNumber);
        }
        showMessage('INFORMATIVO: Correo Electronico', 'Registro encontrado', 'success');
        setIsValidateEmail(false);
        setIsVisible(!isVisible);
        setMensajePago1('Avancemos con su Pago');
        setMensajePago2('Gracias....!');
      }

    }).catch((error) => {
      console.error(error);
      setMensajePago1('Error del Servidor');
      setMensajePago2('Inténtelo más tarde...!');
      showMessage('Error del Servidor', 'Hubo un problema con el servidor. Inténtelo más tarde.', 'error');
    })
  };

  const handleInputChange = (e) => {
    setInputCorreo(e.target.value);
  };

  const handleSameUser = (e) => {
    console.log(e.value);
    setSameUser(e.value);

    const name = getValues('name');
    const lastName = getValues('apa');
    const secondLastName = getValues('am');

    const concatName = `${name} ${lastName} ${secondLastName}`;
    if (e.value) {
      setValue('cardName', concatName);
    } else {
      setValue('cardName', '');
    }
  };

  const handleInputChangeCp = async (e) => {
    const { value } = e.target;
    setCodigoPostal(value);
    if (value.length === 5) {
      console.log(codigoPostal);
      console.log(value);
      await getCodigoPostal(value).then((data) => {
        console.log(data);
        setValue('estado', data[0].ddEstado);
        setValue('delegacion', data[0].ddMnpio);
        setValue('colonia', data[0].ddAsenta);
      })
        .catch((error) => {
          console.error(error);
          showMessage('Error: Código Postal', 'No se encontro el Código Postal', 'error');
          setValue('estado', '');
          setValue('delegacion', '');
          setValue('colonia', '');
        })
        .finally(() => { setValue('cp', value); });
    }
  };

  const showMessage = (header, messError, severity) => {
    if (toast.current) {
      toast.current.show({ severity: severity, summary: header ? header : 'Error', detail: messError, life: 3000 });
    }
  };

  const steps = [
    {
      icon: 'pi pi-user',
      label: 'Personal',
      template: (item) => itemRenderer(item, 0)
    },
    {
      icon: 'pi pi-home',
      label: 'Direccion',
      template: (item) => itemRenderer(item, 1)
    },
    {
      icon: 'pi pi-credit-card',
      label: 'Tarjeta',
      template: (item) => itemRenderer(item, 2)
    }
  ];

  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex === itemIndex;
    const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
    const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

    return (
      <>
        <div className="mb-5">
          <span
            className="inline-flex flex-col align-items-center  justify-content-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer relative"
            style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '-25px' }}
            onClick={() => setActiveIndex(itemIndex)}
          >
            <i className={`${item.icon} text-xl`} />
          </span>
        </div>
        <div>
          <span className="text-ms absolute bottom-0 left-0 right-0 top-5 text-center mt-5">
            {item.label}
          </span>
        </div>
      </>
    );
  };

  const handlePaste = (e) => {
    e.preventDefault();
    console.log('Pegar texto');
    const paste = (e.clipboardData || window.clipboardData).getData('text');
    if (paste.length <= 6) {
      setTokens(paste);
    } else {
      // Manejo opcional: si el texto pegado es más largo que el OTP, manejarlo de alguna manera
      console.log('Texto pegado demasiado largo');
    }
  };

  const customInput = ({ events, props }) => {
    return (
      <input
        {...events}
        {...props}
        type="text"
        className="custom-otp-input-sample"
        onPaste={handlePaste}
      />
    );
  };


  const handleToken = async () => {
    console.log(token);
    await postValidateEmail(inputCorreo, token).then((data) => {
      console.log(data);

      if (data.validatedCustomer) {
        setMensajePago1('Avancemos con su Pago');
        setMensajePago2('Gracias....!');
        setValue('email', inputCorreo);
        setIsValidateEmail(false);
        setIsVisible(!isVisible);
      } else {
        showMessage('Error: Codigo', 'Codigo invalido', 'error');
        setMensajePago1('Error de codigo');
        setMensajePago2('Intente de nuevo...!');
      }

    }).catch((error) => {
      console.error(error);
    })
  }

  return (
    <>
      <Toast ref={toast} />

      <div className="grid mt-2 h-13rem w-full" style={{ overflow: 'hidden' }}>
        <img src={'https://www.shutterstock.com/image-vector/long-banner-set-doodle-seafood-260nw-2188553415.jpg'} alt={'bannerBottom'} className="w-full h-15rem" style={{ objectFit: 'cover' }} />
      </div>

      <div id="correo" className="grid mt-5">
        {/* <div id="correo" className={`animation-duration-500 box ${!isVisible ? 'fadein' : 'fadeout hidden'} grid mt-8` } > */}
        <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3">
          {/* <h1 className={`animation-duration-500 box ${!isVisible ? 'fadein' : 'fadeout hidden'} text-center `} >Para empezar a pagar</h1> */}
          <h1 className="text-center">{mensajePago1}</h1>
          <h1 className="text-center">{mensajePago2}</h1>
          <form onSubmit={handleDisabled} >
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon" style={{ backgroundColor: 'white' }}>
                <i className="pi pi-spin pi-at"></i>
              </span>
              <InputText
                className="p-inputtext-lg text-center"
                type="email"
                placeholder="Correo Electrónico"
                value={inputCorreo}
                onChange={handleInputChange}
                disabled={isVisible}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning p-5"
                size="large"
                disabled={inputCorreo.length <= 10 || isVisible}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>




      <div id="registro" className={`animation-duration-500 box ${isVisible ? 'fadein' : 'fadeout hidden'} grid mt-8`} >
        <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3">
          <form onSubmit={handleSubmit(onSubmit)} >
            <Steps model={steps} activeIndex={activeIndex} readOnly={true} />
            {activeIndex === 0 && (
              <>
                <div className="border-2 border-dashed surface-border border-round mt-3 font-medium">
                  <div className="formgrid grid pt-5 lg:px-4 lg:py-5">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="name">Nombres</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" onClick={() => { handleSameUser({ value: false }) }} />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-12 lg:col-4">
                      <Controller
                        name="apa"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="apa">Apellido Paterno</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="am"
                        control={control}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="am">Apellido Materno</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="formgrid grid pt-5 lg:px-4 lg:py-1">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="email">Correo</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="phone">Celular</label>
                            <InputMask {...field} mask="(99) 99-99-99-99" placeholder="(99) 99-99-99-99" className="p-2 focus:border-primary w-full" />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-end p-2">
                  <Button className="p-2" label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={handleNextClick} />
                </div>
              </>
            )}
            {activeIndex === 1 && (
              <>
                <div className="border-2 border-dashed surface-border border-round mt-3 font-medium">
                  <div className="formgrid grid pt-5 lg:px-4 lg:py-5">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="calle"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="calle">Calle</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-2">
                      <Controller
                        name="numExt"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="numExt">Número exterior</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-2">
                      <Controller
                        name="numInt"
                        control={control}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="numInt">Número interior</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="cp"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="cp">Código Postal</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" keyfilter="num" maxLength={5} value={codigoPostal} onChange={handleInputChangeCp} />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="formgrid grid pt-5 lg:px-4 lg:py-1">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="estado"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="estado">Estado</label>
                            {/* <Dropdown {...field} className="w-full p-2" options={state} placeholder="Estado" optionLabel="name" itemTemplate={countryOptionTemplate} highlightOnSelect={true} /> */}
                            <InputText disabled {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="delegacion"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="delegacion">Delegacion/Municipio</label>
                            <InputText disabled {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="colonia"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="colonia">Colonia</label>
                            <InputText disabled {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-between">
                  <Button className="p-2" label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={handlePrevClick} />
                  <Button className="p-2" label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={handleNextClick} />
                </div>


              </>
            )}

            {activeIndex === 2 && (
              <>
                <div className="border-2 border-dashed surface-border border-round mt-3 font-medium">
                  <div className="formgrid grid pt-5 lg:px-4 lg:py-5">
                    <div className="col-12 lg:col-6">
                      <Controller
                        name="cardName"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <div className="field col">
                              <label htmlFor="cardName">Nombre del titular</label>
                              <IconField iconPosition="left">
                                <InputIcon className="pi pi-user"> </InputIcon>
                                <InputText {...field} defaultValue={getValues('name')} className="p-2 focus:border-primary w-full pl-6" autoComplete="off" data-openpay-card="holder_name" />
                              </IconField>
                              <ToggleButton onLabel="Mismo nombre personal" offLabel="Mismo nombre personal" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="col-12 text-center" />
                              {/* <ToggleButton onLabel="Mismo nombre personal" offLabel="Mismo nombre personal" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="col-12 text-center" tooltip="Agregar mismo nombre del registro personal" tooltipOptions={{ position: 'top' }} /> */}
                              {getFormErrorMessage(field.name)}
                            </div>
                          </>
                        )}
                      />
                    </div>

                    {/* <div className="col-12 lg:col-2">
                      <div className="flex flex-column align-items-center mt-2">
                        <InputSwitch checked={sameUser} onChange={(e) => setSameUser(e.value)} />
                        <ToggleButton onLabel="Mismo nombre" offLabel="Mismo nombre" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="w-9rem p-2 text-center" tooltip="Agregar mismo nombre del registro anterior" tooltipOptions={{ position: 'top' }} />
                        <span className="text-sm text-gray-500 text-center">Mismo nombre </span>
                      </div>
                    </div> */}


                    <div className="col-12 lg:col-6 mb-3">
                      <Controller
                        name="cardNumber"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="cardNumber">Número de tarjeta</label>
                            <IconField iconPosition="left">
                              <InputIcon className="pi pi-credit-card"> </InputIcon>
                              <InputText {...field} className="p-2 focus:border-primary w-full pl-6" keyfilter="num" maxLength={18} minLength={13} autoComplete="off" data-openpay-card="card_number" />
                            </IconField>
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>


                  </div>

                  <div className="grid nested-grid px-4">
                    <div className="field col-12 lg:col-6">
                      <h4 className="text-center">Fecha de expiración</h4>
                    </div>
                  </div>

                  <div className="grid nested-grid px-4 ">
                    <div className="field col-12 md:col-6 ">
                      <Controller
                        name="vigencia"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <div className="align-items-center  justify-content-center">
                              {/* <Dropdown {...field} options={annos} optionLabel="name" optionGroupLabel="label" optionGroupChildren="itemAños" placeholder="Año" optionGroupTemplate={optionLabel} itemTemplate={countryOptionTemplate} className="w-full p-2" highlightOnSelect={true} data-openpay-card="expiration_year" /> */}
                              <InputMask  {...field} className="p-2 focus:border-primary col-12 text-center " mask="99/99" placeholder="99/99" autoComplete="off" data-openpay-card="expiration_year" />
                              {getFormErrorMessage(field.name)}
                            </div>
                          </>

                        )}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <Controller
                        name="cvv2"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <IconField iconPosition="left">
                              <InputIcon className="pi pi-lock"> </InputIcon>
                              <InputText {...field} className="p-2 focus:border-primary w-full pl-6" keyfilter="num" maxLength={3} autoComplete="off" data-openpay-card="cvv2" placeholder="cvv" />
                            </IconField>
                            {getFormErrorMessage(field.name)}
                          </>

                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-start">
                  <Button className="p-2" label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={handlePrevClick} />
                </div>
                <div className="grid mt-5">
                  <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3">
                    <Button className="col-12" size="large" label="PAGAR" type="submit" disabled={!isValid} />
                  </div>
                </div>
              </>
            )}




          </form>
        </div>
      </div >

      {isValidateEmail && (
        <>

          <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3 text-center">
            <div className="card flex justify-content-center">
              <style>
                {`
                    .custom-otp-input-sample {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        text-align: center;confirm2key
                        border: 1px solid var(--surface-400);
                        background: transparent;
                        outline: none;
                        margin: 0;
                        box-sizing: border-box;
                        transition: border-color 0.3s;
                    }

                    .custom-otp-input-sample:focus {
                        border-color: var(--primary-color);
                    }

                    .custom-otp-input-sample:not(:first-child) {
                        border-left: 1px solid var(--surface-400);
                    }

                    .custom-otp-input-sample:first-child {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }

                    .custom-otp-input-sample:last-child {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                `}
              </style>
              <div className="flex flex-column align-items-center">
                <p className="font-bold text-xl mb-2">Auntificacion de correo</p>
                <p className="text-color-secondary block mb-5">Por favor revice su correo e ingrese el codigo</p>
                <InputOtp
                  value={token}
                  onChange={(e) => setTokens(e.value)}
                  length={6}
                  inputTemplate={customInput}
                  style={{ gap: 0 }}
                />
                <div className="flex justify-content-between mt-5 align-self-stretch">
                  <Button label="Limpiar" onClick={() => setTokens('')} link className="p-0"></Button>
                  <Button className="p-2" label="Enviar" onClick={handleToken} disabled={token.length < 6}></Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}


    </>
  );
};

export default FormPayView;

import React from "react";
import { InputText } from 'primereact/inputtext';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from 'primereact/button';

import Image01 from './carrusel/Img01.png';

const SectionSix = () => {
    return (
        <>
            <div className="grid mt-7 md:m-8 lg:m-8">
                <div className="col-12 mt-8 md:col-4 lg:col-4 lg:mt-0">
                    <img src={'https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_azure_logo_icon_170956.png'} alt={Image01} className="w-3"/>
                    <h1>La Fudería, agregar descripción corta.
                    </h1>
                    <div className="inline-flex">
                        <div className="inline font-bold text-center p-5 border-round"><i className="pi pi-facebook" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></div>
                        <div className="inline font-bold text-center p-5 border-round"><i className="pi pi-instagram" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></div>
                        <div className="inline font-bold text-center p-5 border-round"><i className="pi pi-twitter" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></div>
                        <div className="inline font-bold text-center p-5 border-round"><i className="pi pi-youtube" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></div>
                    </div>

                </div>

                <div className="col-12 mt-8 text-center md:col-4 lg:col-4 lg:mt-0">
                    <h1 className="p-3 font-bold">SITEMAP</h1>
                    <h2 className="p-3 font-bold">NOSOTROS</h2>
                    <h2 className="p-3 font-bold">PRODUCTOS</h2>
                    <h2 className="p-3 font-bold">MI CUENTA</h2>
                    <h2 className="p-3 font-bold">LEGALES</h2>
                    <h2 className="p-3 font-bold">CONTACTO</h2>
                </div>

                <div className="col-12 mt-8 md:col-4 lg:col-4 lg:mt-0">
                    <h1 className="font-bold">NEWSLETTER</h1>
                    <h2>Suscríbete para recibir noticias, acceder a ofertas exclusivas y más.</h2>
                    <div className="field">
                        <IconField className="col-12" iconPosition="left">
                            <InputIcon style={{ top: "1.5rem", left: '1rem' }} className="pi pi-spin pi-at"> </InputIcon>
                            <InputText className="col-12 p-inputtext-lg text-center" type="email" placeholder="Ingresa tu Correo Electrónico..." />
                        </IconField>
                    </div>
                    <div className="field">
                        <Button className="col-12" size="large" label="SUSCRIBIRSE" />
                    </div>
                </div>
            </div>


            <div className="grid mt-2 h-13rem w-full" style={{overflow: 'hidden' }}>
                <img src={'https://www.shutterstock.com/image-vector/long-banner-set-doodle-seafood-260nw-2188553415.jpg'} alt={'bannerBottom'} className="w-full h-15rem" style={{ objectFit: 'cover' }} />
            </div>
        </>
    );
}

export default SectionSix;
import { combineReducers } from 'redux';

// Aquí importa tus reducers específicos que crearás para tu aplicación
// import ejemploReducer from './ejemploReducer'
import singInReducer from './auth/authReducer';
import productViewerReducer from './shop/productViewerReducer';
import shopReducer from './shop/shopReducer';


// Combina todos los reducers en uno solo
const rootReducer = combineReducers({
  // ejemplo: ejemploReducer,
  singInReducer,
  productViewerReducer,
  shopReducer,
});

export default rootReducer;

import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useResizeListener } from 'primereact/hooks';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { BlockUI } from 'primereact/blockui';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { LayoutProvider } from './components/layout/context/layoutcontext';
import Layout from './components/layout/layout';
import { useTranslation } from 'react-i18next';
import global from './context/global.context';
import './assets/css/custom.css';
import './assets/styles/layout/layout.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import routes from './routes';
//import { FloatingWhatsApp } from 'react-floating-whatsapp';

//import logo from './assets/images/inemex_logo.jpeg';
import PrivateRoute from './common/PrivateRoute';
import ShopContainer from './views/public/shopContainer';
import ShoppingCart from './views/public/shoppingCart';
import LandingPage from './views/home/LandingPage';
import FormPay from './views/public/FormPay';

const App = () => {
  const toast = useRef(null);
  const { i18n } = useTranslation();
  const [globalIdiom, setGlobalIdiom] = useState('es');
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 991);
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem('profile')),
  );
  const [sessionToken, setSessionToken] = useState(
    localStorage.getItem('token'),
  );

  const [bindWindowResizeListener, unbindWindowResizeListener] =
    useResizeListener({
      listener: (event) => {
        const windowWidth = event.currentTarget.innerWidth;
        setIsDesktop(windowWidth >= 1024);
      },
    });

  useEffect(() => {
    bindWindowResizeListener();

    return () => {
      unbindWindowResizeListener();
    };
  }, [bindWindowResizeListener, unbindWindowResizeListener]);

  useEffect(() => {
    i18n.changeLanguage(globalIdiom);
  }, [globalIdiom, i18n]);

  const getRoutes = useMemo(() => {
    const rs = routes.map((route) => {
      if (route.route) {
        return (
          <Route
            path={route.route}
            key={route.key}
            element={<PrivateRoute>{route.component}</PrivateRoute>}
          />
        );
      }
      return null;
    });
    return rs;
  }, []);

  return (
    <global.Provider
      value={{
        toast,
        isDesktop,
        globalIdiom,
        blockedPanel,
        sessionToken,
        profile,
        setProfile,
        setSessionToken,
        setGlobalIdiom,
        setBlockedPanel,
      }}
    >
      <ConfirmDialog />
      <LayoutProvider>
        <Layout>
          <Toast ref={toast} />
          <BlockUI
            blocked={blockedPanel}
            fullScreen
            template={<i className="pi pi-lock" style={{ fontSize: '3rem' }} />}
          />
          <Routes>
            {getRoutes}
            <Route path="/" element={<Navigate to="/landing-page" />} />
            <Route path="/landing-page" element={<LandingPage />} />
            <Route path="/pay-page" element={<FormPay />} />
            <Route path="/shop" element={<ShopContainer />} />
            <Route path="/shopping-cart" element={<ShoppingCart />} />
          </Routes>
        </Layout>
      </LayoutProvider>
    </global.Provider>
  );
};

export default App;

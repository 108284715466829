import React, {
  forwardRef,
  useContext,
  useRef,
  useImperativeHandle,
  useEffect,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import img from '../../assets/images/lficon.png';
import { LayoutContext } from './context/layoutcontext';
import { useDispatch } from 'react-redux';
//import { useSelector } from 'react-redux';
import global from '../../context/global.context';
import {
  loadElementsFromStorage,
  loadElementsToShoppingCart,
} from '../../redux/actions/shop/shopActions';
import './style.css';

const AppTopbar = forwardRef((props, ref) => {
  const navigate = useNavigate();
  const menubuttonRef = useRef(null);
  const topbarmenuRef = useRef(null);
  const topbarmenubuttonRef = useRef(null);
  const layoutcontext = useContext(LayoutContext);
  const globalContext = useContext(global);
  const { sessionToken } = globalContext;
  const { layoutState, showProfileSidebar, onMenuToggle } = layoutcontext;
  //const state = useSelector((state) => state.shopReducer);
  const dispatch = useDispatch();

  //const { shoppingCart } = state;

  const fakeInvoke = () => {
    const demo = [
      {
        id: '5125',
        code: 'f230fh0g3',
        name: 'Bamboo Watch',
        description: 'Product Description',
        image: 'bamboo-watch.jpg',
        price: 65,
        category: 'Accessories',
        quantity: 24,
        inventoryStatus: 'INSTOCK',
        rating: 5,
      },
    ];
    return demo;
  };

  useEffect(() => {
    console.log('me llame appTopBar');
    if (sessionToken) {
      const elements = fakeInvoke();
      dispatch(loadElementsToShoppingCart(elements));
    } else {
      dispatch(loadElementsFromStorage());
    }
  }, [sessionToken, dispatch]);

  useImperativeHandle(ref, () => ({
    menubutton: menubuttonRef.current,
    topbarmenu: topbarmenuRef.current,
    topbarmenubutton: topbarmenubuttonRef.current,
  }));

  /*const handleLogOut = () => {
    localStorage.clear();
    window.location.href = '/';
  };*/

  const relocateToShoppingCart = () => {
    navigate('/shopping-cart');
  };

  return (
    <div className="layout-topbar">
      <Tooltip target="#button" />

      <Link to="/" className="layout-topbar-logo">
        <img src={img} alt="header-img" />
      </Link>

      <button
        ref={menubuttonRef}
        type="button"
        className="p-link layout-menu-button layout-topbar-button"
        onClick={onMenuToggle}
        style={{ display: 'none' }}
      >
        <i className="pi pi-bars" />
      </button>

      <button
        ref={topbarmenubuttonRef}
        type="button"
        className="p-link layout-topbar-menu-button layout-topbar-button"
        onClick={showProfileSidebar}
      >
        <i className="pi pi-ellipsis-v" />
      </button>

      <div
        ref={topbarmenuRef}
        className={classNames('layout-topbar-menu', {
          'layout-topbar-menu-mobile-active': layoutState.profileSidebarVisible,
        })}
      >
        <Button className="topbar-text" label="NOSOTROS" text severity="secondary" size="large" />
        <Button className="topbar-text" label="PRODUCTOS" text severity="secondary" size="large" />
        <Button className="topbar-text" label="RECETAS" text severity="secondary" size="large" />
        <Button className="topbar-text" label="CONTACTO" text severity="secondary" size="large" />

        <Button className="topbar-button" icon="pi pi-search" rounded text aria-label="Filter" size="large" />
        <Button className="topbar-button" icon="pi  pi-heart" rounded text aria-label="Filter" size="large" />
        <Button className="topbar-button" icon="pi pi-shopping-cart" rounded text aria-label="Filter" size="large" onClick={relocateToShoppingCart} />
        <Button className="topbar-button" icon="pi pi-user" rounded text aria-label="Filter" size="large" />


      </div>
    </div>
  );
});

export default AppTopbar;

import { useState } from "react";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";

const useFormPayHook = () => {
    const { getDataFetch: codigoPostaal } = useLazyFetch();
    const { getDataFetch: busquedaCliente } = useLazyFetch();
    // const { getDataFetch: ValidateEmail } = useLazyFetch();
    const { getDataFetch: validateEmail } = useLazyFetch();
    const [direccion, setDireccion] = useState(null);
    const [dataRegsitro, setDataRegsitro] = useState(null);

    const getCodigoPostal = async (cp) => {
        const { data, errorFetch } = await codigoPostaal(`/public/catalogs/find-by-cp/${cp}`, 'GET', {});
        console.log(data);
        if (data) {
            setDireccion(data);
            return data;
        }
        console.error(errorFetch);
    };

    const getBuscaCorreo = async (correo) => {
        const { data, errorFetch } = await busquedaCliente(`/public/checkout/profile/${correo}`, 'GET', {});

        if (data) {
            setDataRegsitro(data);
            return data;
        }

        if (errorFetch) {
            console.error(errorFetch);
            return errorFetch;
        }

    };


    const postValidateEmail = async (email, code) => {
        console.log(email, code);
        const rq = {
            "email": email,
            "code": code
        }

        const { data, errorFetch } = await validateEmail(`/public/checkout/profile/validateEmail`, 'POST', { rq });

        if (data) {
            console.log(data);
            return data;
        }

        if (errorFetch) {
            console.error(errorFetch);
            return errorFetch;
        }

    };

    const onSubmit = async (rq) => {
        console.log(rq);
    };


    return {
        functions: {
            onSubmit,
            getCodigoPostal,
            getBuscaCorreo,
            postValidateEmail
        },
        states: {
            direccion,
            dataRegsitro
        }
    };
}

export default useFormPayHook;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2"
import { clearCart } from "../../../../redux/actions/shop/shopActions";
import { useNavigate } from "react-router-dom";

const usePriceBoxContainerHook = () => {
    const navigate = useNavigate();
    const [totalProducts, setTotalProducts] = useState(0);
    const [total, setTotal] = useState(0);
    const [shipping, setShipping] = useState(0);
    const [totalAndAhipping, setTotalAndAhipping] = useState(0);

    const shopState = useSelector((state) => state.shopReducer);
    const { shoppingCart, purchaseCart } = shopState;
    const dispatch = useDispatch();
    const { getDataFetch } = useLazyFetch();

    useEffect(() => {
        if (shoppingCart) {
            let totalProducts = 0;
            let total = 0;
            let shipping = 155;
            shoppingCart.forEach((element) => {
                const fullItem = purchaseCart.find((item) => item.productId === element.id);
                console.log("fullItem", fullItem);
                const price = parseFloat(fullItem.price ? fullItem.price : 0);
                console.log("price", price);
                const quantity = parseInt(element.quantity);
                totalProducts += quantity;
                total += price * quantity;
            });
            setTotalProducts(totalProducts);
            setTotal(total);
            setTotalAndAhipping(total + shipping);
            setShipping(shipping);
        }

    }, [shoppingCart, purchaseCart]);

    const continueFlow = async () => {
        const rq = {
            "cardId": "k7jpgu0ncuxngiafhs1g",
            amount: totalAndAhipping,
            description: `Compra de ${totalProducts} productos del dia ${new Date().toLocaleDateString()}`,
            deviceSessionId: "WH7hZxBapmruncPkFY2NhOQ7FrXrgmeU"
        }
        const { data } = await getDataFetch('/payment-system/charges/axpxpzhrq3vntipz5bll', 'POST', { rq });

        if (data) {
            dispatch(clearCart());
            navigate('/shop');
        }
    }

    return {
        totalProducts,
        shipping,
        total,
        totalAndAhipping,
        continueFlow
    }
}

export default usePriceBoxContainerHook;

import '../css/SectionTwo.css';

const SectionTwo = () => {
    return (
        <>
            <div className="grids">
                <h1 className="title">¿QUIÉNES SOMOS?</h1>
            </div>



            <div className="grid">
                <div className="col-12 md:col-6 lg:col-3 hidden lg:block">
                    <img className="w-8 p-4" src="https://m.media-amazon.com/images/I/617Uj+EENzL._AC_UF894,1000_QL80_.jpg" alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-3 hidden lg:block">
                    <img className="w-8 p-4" src="https://m.media-amazon.com/images/I/617Uj+EENzL._AC_UF894,1000_QL80_.jpg" alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-3 text-center">
                    <img className="w-8 p-4" src="https://m.media-amazon.com/images/I/617Uj+EENzL._AC_UF894,1000_QL80_.jpg" alt="" />
                </div>
                <div className="col-12 md:col-6 lg:col-3">
                    <div className="text">
                        Somos un grupo de amigxs que quiere romper con la idea que comer rico es comer caro. Ofrecemos una opción mexa donde puedes encontrar tus proteinas favoritas, cuidando el medio ambiente, ayudando a proveedores locales y donde la calidad no es un lujo, !es una promesa!.
                    </div>
                </div>
            </div>

            <div className="grids">
                <h1 className="title">PROPUESTA DE VALOR</h1>
            </div>

            <div className="grid mt-5">
                <div className="col-12 md:col-6 lg:col-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-center p-3 border-round-sm">
                        <img className="w-3" src="https://static.vecteezy.com/system/resources/previews/021/521/591/non_2x/old-man-cartoon-fishing-trout-fish-vector.jpg" alt="" />
                    </div>
                    <div className="text-center p-3 border-round-sm">
                        <p className='text01'>Compromiso con el medio ambiente <br></br>y nuestras comunidades</p>
                    </div>
                </div>

                <div className="col-12 md:col-6 lg:col-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-center p-3 border-round-sm">
                        <img className="w-3" src="https://w7.pngwing.com/pngs/770/574/png-transparent-shop-open-online-store-shopping-and-ecommerce-icon.png" alt="" />
                    </div>
                    <div className="text-center p-3 border-round-sm">
                        <p className='text01'>La mejor calidad en tus productos</p>
                    </div>
                </div>

                <div className="col-12 md:col-6 lg:col-4 d-flex flex-column justify-content-center align-items-center">
                    <div className="text-center p-3 border-round-sm">
                        <img className="w-3" src="https://images.vexels.com/media/users/3/128397/isolated/lists/8e4f1c1ccae7956d9ba9bd0a5fa885d5-paquete-de-dibujos-animados-hombre-de-entrega.png" alt="" />
                    </div>
                    <div className="text-center p-3 border-round-sm">
                        <p className='text01'>Seguridad en tu compra y entrega</p>
                    </div>
                </div>
            </div>

        </>

    )

}
export default SectionTwo;